import axiosInstance from "../plugins/axios.js";

export async function makeGetRequest(url, options = {}) {
  return axiosInstance
    .get(url, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function makePostRequest(url, body = null, options = {}) {
  return axiosInstance
    .post(url, body, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function makePutRequest(url, body = null, options = {}) {
  return axiosInstance
    .put(url, body, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
