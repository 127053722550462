import axios from "axios";
import Cookies from "js-cookie";

const _ENV = process.env;
const isProductionMode = _ENV.NODE_ENV == "production";
const url = !isProductionMode
  ? _ENV.VUE_APP_BASE_URL_API_DEV
  : _ENV.VUE_APP_BASE_URL_API_PROD;

let headersOpt = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const axiosInstance = axios.create({
  baseURL: url,
  headers: headersOpt,
});

// Función para obtener el token y verificar su expiración
const getToken = () => {
  const tokenData = Cookies.get('authToken');
  if (tokenData) {
    const parsedTokenData = JSON.parse(tokenData);
    const { token, expiry } = parsedTokenData;
    if (new Date().getTime() < expiry) {
      return token;
    }
  }
  return null;
};

// Agregar token para las peticiones
axiosInstance.interceptors.request.use(config => {
  const token = getToken();
  const loginUrl = '/users/login'; // URL de inicio de sesión

  if (config.url === loginUrl) {
    config.headers.Authorization = `Bearer ${_ENV.VUE_APP_API_TOKEN}`;
  } else if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;