import { makeGetRequest } from "./querysAPI.js";
import Cookies from "js-cookie";
import store from "@/store";

// const urlCheckUser = "/users/check/";

export const loginUser = (loginInfo) => {
  const { access_token, expires_in } = loginInfo;

  // Guardar la información de usuario en el store
  store.commit("setUserInformation", {
    userRole: loginInfo.user.userRole,
    imageAvatar: loginInfo.user.userImgAvatar,
    userFullName: loginInfo.user.userName,
  });

  // Guardar el token dentro de las cookies
  saveToken(access_token, false, expires_in);
};

export const logoutUser = async () => {
  try {
    // Envía la petición de cierre de sesión a la api
    const logginout = await makeGetRequest('users/logout');
    if (logginout.success) {
      Cookies.remove("authToken");
    }
  } catch (error) {
    console.error("Error al cerrar sesión", error);
  }
};

// Verificar si el usuario tiene una sesión activa
export const checkUser = async () => {
  const session = Cookies.get("authToken");

  if (!session) {
    logoutUser();
    return false;
  }

  return true;
};

// TODO: Implementar esta función
// Hace lo mismo que checkUser pero consulta los datos al server
/* export const checkUserOnline = async () => {
  const session = sessionStorage.getItem("userSession");

  if (!session) {
    logoutUser();
    return false;
  }

  const userSession = JSON.parse(session);

  const requestResponse = await makeGetRequest(
    urlCheckUser + userSession.userId
  );

  if (!requestResponse.success) {
    // Trigger logout for security
    logoutUser();
    return false;
  }

  const checkedId = userSession.userId === requestResponse.data.userId;
  const checkedRole = userSession.userRole === requestResponse.data.userRole;

  if (!checkedId && !checkedRole) {
    logoutUser();
    return false;
  }
  return true;
}; */

export const getDataSession = () => {
  return store.getters['getUserInformation'];
};

// Función para guardar el token con la expiración
const saveToken = (token, rememberMe, expiry) => {
  const expiryTime = new Date().getTime() + (rememberMe ? 30 * 24 * 60 * 60 * 1000 : expiry * 1000); // 30 días o 1 día
  const expiryDate = new Date(expiryTime);
  const tokenData = { token, expiry: expiryTime };
  Cookies.set('authToken', JSON.stringify(tokenData), { expires: expiryDate });
};
