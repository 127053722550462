<script setup>
import { makeGetRequest, makePostRequest } from "@/utils/querysAPI.js";
import { onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { showToast } from "@/utils/modalShow.js";
// import sisconImg from '@/assets/img/icon-rounded-100.png';
import { formatPhoneNumber } from "@/utils/formatters.js";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { useRoute } from "vue-router";
// import { getUserRoleText } from "@/utils/helper.js";
import { ElSelect, ElInput } from "element-plus";
import { useStore } from "vuex";
import { bouncy } from 'ldrs';

bouncy.register();
const store = useStore();
const route = useRoute();
const userInputInitial = {
    fname: '',
    lname: '',
    username: '',
    email: '',
    address: '',
    city: '',
    province: '',
    country: '',
    zipCode: '',
    phone: '',
    userRole: null,
    imageUrl: '',
};
const userInfo = ref({});
const rolesOptions = ref([
    {
        label: "Administrador",
        value: "ADMIN",
    },
    {
        label: "Cajero",
        value: "CASHIER",
    },
    {
        label: "Vendedor",
        value: "SELLER",
    },
]);
const showLoadingButton = ref(false);
const checkPassword = ref(true);
const checkedPasswords = ref(false);
const checkedUsername = ref(null);
const checkedEmail = ref(null);
const usernameNotification = ref("");
const emailNotification = ref("Revise el formato del Email.");
let searchTimeout = null; // Timeout searcher
// const dataSession = ref(getDataSession());
// const updatePassword = ref(false);
/* const loadProfileData = async () => {
    try {
        const businessId = dataSession.value.businessId;
        const userId = dataSession.value.userId;
        const request = await makeGetRequest(`/users/data/${businessId}/${userId}`);
        if (request.success) {
            userInfo.value = request.data.business_user;
        } else {
            throw new Error(request);
        }
    } catch (error) {
        console.log(error);
    }
}; */
const sendNewUser = async () => {
    showLoadingButton.value = true;

    // Guardar datos
    if (!checkedPasswords.value || !checkedUsername.value || !checkedEmail.value) {
        showToast.fire({
            icon: 'error',
            title: 'Hay errores en los datos. Por favor, revise.'
        });
        return;
    }

    try {
        const dataToSend = {
            data: userInfo.value,
        }
        const request = await makePostRequest(`users/new`, dataToSend);
        if (request.success === true) {
            showToast.fire({
                icon: 'success',
                title: 'Datos guardados correctamente'
            });
            resetUserInfo();
            showLoadingButton.value = false;
            return;
        } else {
            showLoadingButton.value = false;
            const message = typeof request.msg === 'string' ? request.msg : 'Error al guardar los datos';
            showToast.fire({
                icon: 'error',
                title: message,
            });
            throw new Error(request);
        }
    } catch (error) {
        console.log(error);
    }

    showLoadingButton.value = true;
};
const handleCheckPasswords = () => {
    // Chequear contraseñas
    if (userInfo.value.password !== userInfo.value.repeatPassword) {
        checkPassword.value = false;
        checkedPasswords.value = false;
        return false;
    } else {
        checkPassword.value = true;
        checkedPasswords.value = true;
        return true;
    }
};
const resetUserInfo = () => {
    Object.assign(userInfo.value, userInputInitial);
};
const handleUsername = async (event) => {
    showLoadingButton.value = true;
    checkedUsername.value = null;

    clearTimeout(searchTimeout);

    if (event.target.value === '') {
        return;
    }

    // Como mínimo tiene que tener 3 caracteres para la búsqueda
    if (event.target.value.length <= 2) {
        return;
    }

    searchTimeout = setTimeout(async () => {
        try {
            checkUsername()
        } catch (error) {
            console.error('Error fetching checkUsername:', error);
        }
        showLoadingButton.value = false;
    }, 500);
};

const handleEmail = async (event) => {
    showLoadingButton.value = true;
    checkedEmail.value = null;

    clearTimeout(searchTimeout);

    if (event.target.value === '') {
        return;
    }

    // Como mínimo tiene que tener 3 caracteres para la búsqueda
    if (event.target.value.length <= 2) {
        return;
    }

    searchTimeout = setTimeout(async () => {
        try {
            checkEmail()
        } catch (error) {
            console.error('Error fetching checkEmail:', error);
        }
        showLoadingButton.value = false;
    }, 500);
};
const checkEmail = async () => {
    // Chequear si el email ya existe
    try {
        const dataToSend = {
            username: userInfo.value.username,
            email: userInfo.value.email,
        }
        const request = await makePostRequest(`users/check-email`, dataToSend);
        if (request.success) {
            checkedEmail.value = true;
            return;
        } else {
            if (typeof request.msg === 'string') {
                emailNotification.value = request.msg;
                checkedEmail.value = false;
            } else {
                emailNotification.value = "Revise el formato del Email.";
                checkedEmail.value = false;
            }
            return;
        }
    } catch (error) {
        console.log(error);
    }
};
const setUserInfo = (user) => {
    console.log(user);
    const businessUser = user.business_user;
    userInfo.value.id = businessUser.userId;
    userInfo.value.fname = businessUser.fname;
    userInfo.value.lname = businessUser.lname;
    userInfo.value.email = businessUser.email;
    userInfo.value.imageUrl = businessUser.imageUrl;
    userInfo.value.userRole = user.role;
    userInfo.value.address = businessUser.address;
    userInfo.value.city = businessUser.city;
    userInfo.value.province = businessUser.province;
    userInfo.value.country = businessUser.country;
    userInfo.value.zipCode = businessUser.zipCode;
    userInfo.value.phone = businessUser.phone;
    userInfo.value.username = businessUser.username;
};

const checkUsername = async () => {
    // Chequear si el usuario ya existe
    if (userInfo.value.username !== '') {
        try {
            const dataToSend = {
                username: userInfo.value.username,
            }
            const request = await makePostRequest(`users/check-username`, dataToSend);
            if (request.success) {
                checkedUsername.value = true;
                return;
            } else if (request.code === "U100") {
                usernameNotification.value = request.msg;
                checkedUsername.value = false;
                return;
            } else {
                usernameNotification.value = "El usuario ya existe.";
                checkedUsername.value = false;
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }
};
const getUserInfo = async (id) => {
    try {
        const request = await makeGetRequest(`users/data/${id}`);
        if (request.success) {
            setUserInfo(request.data);
        }
    } catch (error) {
        console.log(error);
        showToast.fire({
            icon: "error",
            title: "Ha surgido un error al consultar los datos del usuario. Intente nuevamente."
        });
    }
};

watch(() => route.params.id, async (newId) => {
    if (newId > 0) {
        await getUserInfo(route.params.id);
    }
})

onBeforeMount(() => {
    store.state.openedMenu = '';
});
onBeforeUnmount(() => {
    store.state.openedMenu = '';
});
onMounted(async () => {
    if (route.params.id > 0) {
        await getUserInfo(route.params.id);
    } else {
        userInfo.value = { ...userInputInitial };
    }
});
</script>
<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <div class="py-4 container-fluid">
                    <div class="row">
                        <div class="card">
                            <div class="card-header pb-0">
                                <div class="text-center">
                                    <p class="mb-0">{{ route.params.id > 0 ? "Editar usuario" : "Crear usuario"}}</p>
                                </div>
                            </div>
                            <div class="card-body">
                                <form role="form" @submit.prevent="sendNewUser">
                                    <p class="text-uppercase text-sm">Información de usuario</p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">Nombre</label>
                                            <argon-input id="fname" type="text" v-model="userInfo.fname"
                                                :isRequired="true" />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Apellido</label>
                                            <argon-input id="lname" type="text" v-model="userInfo.lname"
                                                :isRequired="true" />
                                        </div>

                                        <div v-if="!route.params.id" class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Usuario</label>
                                            <argon-input id="username" :error="!checkedUsername && userInfo.username !== ''" type="text" :autoComplete="'off'"
                                                v-model="userInfo.username" :isRequired="true" @keyup="handleUsername" @blur.prevent="handleUsername" />
                                            <div v-if="!checkedUsername && userInfo.username !== ''" class="text-danger text-xs text-center w-100" style="margin-top:-10px;">{{ usernameNotification }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Correo
                                                electrónico</label>
                                            <argon-input id="email" type="email" autoComplete="off" @keyup="handleEmail" @blur="handleEmail" v-model="userInfo.email" :error="!checkedEmail && userInfo.email !== ''" :isRequired="true" />
                                            <div v-if="!checkedEmail && userInfo.email !== ''" class="text-danger text-xs text-center w-100" style="margin-top:-10px;">{{ emailNotification }}</div>
                                        </div>
                                        <div v-if="!route.params.id" class="col-md-6">
                                            <label for="example-text-input"
                                                class="form-control-label">Contraseña</label>
                                                <el-input
                                                    name="password"
                                                    v-model="userInfo.password"
                                                    type="password"
                                                    @input="handleCheckPasswords"
                                                    :input-style="checkedPasswords ? 'border:2px solid #fd5c70;' : 'border-color:2px solid #66d432;'"
                                                    size="large"
                                                    show-password
                                                />

                                        </div>
                                        <div v-if="!route.params.id" class="col-md-6">
                                            <label for="example-text-input" class="form-control-label">Repetir
                                                contraseña</label>
                                            <el-input
                                                name="repeatPassword"
                                                v-model="userInfo.repeatPassword"
                                                type="password"
                                                @input="handleCheckPasswords"
                                                :input-style="checkedPasswords ? 'border:2px solid #fd5c70;' : 'border-color:2px solid #66d432;'"
                                                size="large"
                                                show-password
                                            />
                                        </div>
                                    </div>
                                    <div v-if="userInfo.userRole !== 'OWNER'">
                                        <hr class="horizontal dark" />
                                        <p class="text-uppercase text-sm">Comportamiento de usuario</p>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label class="form-control-label">Rol de usuario</label>
                                                <el-select v-model="userInfo.userRole" no-data-text="Sin datos"
                                                    placeholder="Seleccione un rol" size="large" style="width: 100%">
                                                    <el-option v-for="item in rolesOptions" :key="item.value"
                                                        :label="item.label" :value="item.value" :isRequired="true" />
                                                </el-select>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="horizontal dark" />
                                    <p class="text-uppercase text-sm">Mas información</p>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="form-control-label">Dirección</label>
                                            <argon-input id="address" type="text" v-model="userInfo.address" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Ciudad</label>
                                            <argon-input id="city" type="text" v-model="userInfo.city" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Provincia</label>
                                            <argon-input id="province" type="text" v-model="userInfo.province" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">País</label>
                                            <argon-input id="country" type="text" v-model="userInfo.country" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Código
                                                postal</label>
                                            <argon-input id="zipCode" type="text" v-model="userInfo.zipCode" />
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Teléfono</label>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <span class="input-group-text">
                                                        <i class="fa-solid fa-phone"></i>
                                                    </span>
                                                    <input id="phone" type="tel" class="form-control"
                                                        @input="formatPhoneNumber" v-model="userInfo.phone"
                                                        pattern="\d*" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="horizontal dark" />
                                    <div class="row d-flex justify-content-center mt-4">
                                        <div class="w-30">
                                            <argon-button type="submit" id="saveUserData" color="info" size="md"
                                                class="d-flex flex-row gap-3 align-items-center justify-content-center w-100"
                                                :disabled="showLoadingButton">
                                                <l-bouncy v-if="showLoadingButton" size="30" speed="1" color="white"
                                                    stroke-length=".15" />
                                                <div v-else>
                                                    <i class="fa-regular text-lg fa-floppy-disk me-2"></i>
                                                    {{ route.params.id > 0 ? "Guardar datos" : "Crear usuario" }}
                                                </div>
                                            </argon-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
