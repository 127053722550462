import { makePostRequest } from "@/utils/querysAPI.js";
import { showToast } from "@/utils/modalShow.js";
import Swal from "sweetalert2";

export const billStatus = {
  ticket: "PRINTED_TICKET",
  bill: "PRINTED_BILL",
  created: "CREATED",
  afip_sent: "SENT_SUCCESSFULL",
};

export const userRoles = {
  owner: "OWNER",
  admin: "ADMINISTRATOR",
  seller: "SELLER",
  cashier: "CASHIER",
};

export const getUserRoleText = (role) => {
  switch (role) {
    case "OWNER":
      return "Propietario";
    case "ADMINISTRATOR":
      return "Administrador";
    case "SELLER":
      return "Vendedor";
    case "CASHIER":
      return "Cajero";
  }
};

export const eraseIframeExists = () => {
  // Erase iframe if exists
  const isIframe = document.getElementsByTagName("iframe")[0];
  if (isIframe) {
    document.body.removeChild(isIframe);
  }
};

export const printBill = async (id) => {
  try {
    await Swal.fire({
      title: "Impresión de comprobante",
      icon: "warning",
      html: "Elige el tipo de comprobante para imprimir. <br><small><em>(Presiona ESC para salir)</em></small>",
      showCancelButton: false,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: `<i class="fa-solid fa-print me-2"></i> Ticket`,
      denyButtonText: `<i class="fa-solid fa-print me-2"></i> Factura`,
      // cancelButtonText: `Salir`,
      customClass: {
        confirmButton: "btn btn-info btn-sweet-custom text-lg me-3",
        denyButton: "btn btn-warning text-lg me-3",
        cancelButton: "btn btn-secondary text-lg me-3",
      },
      buttonsStyling: false,
      showClass: {
        popup: "customSwal2",
      },
    }).then(async (result) => {
      const billingData = {
        billingId: id,
      };

      if (result.isConfirmed) {
        billingData.billType = "ticket";
      } else if (result.isDenied) {
        billingData.billType = "bill";
      } else {
        return;
      }

      Swal.fire({
        text: "Cargando impresión...",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      // TODO: chunk function another file.js
      const dataToShow = await makePostRequest(
        "/billing/get-bill-printing",
        billingData
      );
      if (!dataToShow.success) {
        throw new Error(dataToShow.toString());
      } else {
        const decodedPdf = atob(dataToShow.pdfEncoded);
        const byteNumbers = new Array(decodedPdf.length);
        for (let i = 0; i < decodedPdf.length; i++) {
          byteNumbers[i] = decodedPdf.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);

        // Mostrar el PDF en un iframe
        const iframe = document.createElement("iframe");
        iframe.src = pdfUrl;
        iframe.style.display = "none";
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        document.body.appendChild(iframe);

        Swal.close();

        // Imprimir el PDF automáticamente al cargar la página
        iframe.contentWindow.onload = () => {
          iframe.contentWindow.print();
        };
      }
    });
  } catch (error) {
    console.log(error);
    showToast.fire({
      icon: "error",
      title:
        "Ha surgido un error al imprimir el comprobante. Se guardó el estado. Para intentar imprimirlo nuevamente diríjase al listado de ventas.",
    });
  }
};

export const prePrintSend = async (id) => {
  await Swal.fire({
    title: "Imprimir | Enviar el comprobante",
    icon: "warning",
    html: "Elije una opción.",
    showCancelButton: true,
    allowOutsideClick: true,
    showDenyButton: true,
    confirmButtonText: `<i class="fa-solid fa-print me-2"></i> Imprimir`,
    denyButtonText: `<i class="fa-solid fa-share me-2"></i> Enviar`,
    cancelButtonText: `Salir`,
    customClass: {
      confirmButton: "btn btn-info btn-sweet-custom text-lg me-3",
      denyButton: "btn btn-warning text-lg me-3",
      cancelButton: "btn btn-secondary text-lg me-3",
    },
    buttonsStyling: false,
    showClass: {
      popup: "customSwal2",
    },
  }).then(async (result) => {
    if (result.isDenied) {
      console.log("Enviar");
    } else if (result.isConfirmed) {
      await printBill(id);
    }
  });
};

export const showIsNull = (str, retVal) => {
  return String(str) === null ||
    str === undefined ||
    String(str) === "" ||
    Number(str) === 0
    ? retVal
    : str;
};
