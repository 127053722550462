<script setup>
import { makeGetRequest, makePostRequest } from "@/utils/querysAPI.js";
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { showToast, showModal } from "@/utils/modalShow.js";
import sisconImg from '@/assets/img/icon-rounded-100.png';
import { formatPhoneNumber } from "@/utils/formatters.js";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { getUserRoleText } from "@/utils/helper.js";
import Loader from "@/views/components/Loader.vue";
import { useStore } from "vuex";
import { bouncy } from 'ldrs';

bouncy.register();
const store = useStore();
const userInfo = ref({});
const roleText = ref('');
const pageIsLoading = ref(true);
const showLoadingButton = ref(false);
const checkPassword = ref(true);
const checkedPasswords = ref(false);
const updatePassword = ref(false);
const loadProfileData = async () => {
  try {
    const request = await makeGetRequest(`/users/data`);
    if (request.success) {
      roleText.value = getUserRoleText(request.data.role);
      userInfo.value = request.data.business_user;
    } else {
      throw new Error(request);
    }
  } catch (error) {
    console.log(error);
  }
};
const handleCheckPasswords = () => {
  if (updatePassword.value) {
    // Chequear contraseñas
    if (userInfo.value.password !== userInfo.value.repeatPassword) {
      checkPassword.value = false;
      checkedPasswords.value = false;
      return false;
    } else {
      checkPassword.value = true;
      checkedPasswords.value = true;
      return true;
    }
  }
  return true;
};
const handleUpdatePassword = () => {
  updatePassword.value = !updatePassword.value;
};
const preSendInfo = () => {
  if (!handleCheckPasswords()) {
    showToast.fire({
      icon: "error",
      title: "Las contraseñas no coinciden, por favor revise."
    });
    return;
  }

  showModal({
    title: "¡Atención!",
    text: "Estás a punto de guardar la información nueva para tu perfil, ¿Estás seguro de continuar?",
    icon: "warning",
    showCancel: true,
    outClick: true,
    confirmText: "Continuar",
    cancelText: "Cancelar",
  }).then(async (result) => {
    if (result.isConfirmed) {
      showLoadingButton.value = true;
      await sendInfoProfile();
      showLoadingButton.value = false;
    }
  });
};
const sendInfoProfile = async () => {
  try {
    const dataToSend = { ...userInfo.value };
    dataToSend.changePassword = updatePassword.value;
    dataToSend.password = document.getElementById('password').value;
    dataToSend.repeatPassword = document.getElementById('repeatPassword').value;

    const successUser = await makePostRequest('/users/edit-profile', dataToSend);

    if (!successUser.success) {
      throw new Error(successUser.toString());
    } else {
      showLoadingButton.value = false;
      showToast.fire({
        icon: "success",
        title: "Se han guardado los datos del perfil correctamente."
      });
    }
  } catch (error) {
    console.log(error);
    showToast.fire({
      icon: "error",
      title: "Ha surgido un error al guardar los datos. Intente nuevamente."
    });
  }
};

onBeforeMount(() => {
  store.state.openedMenu = '';
});
onBeforeUnmount(() => {
  store.state.openedMenu = '';
});
onMounted(async () => {
  await loadProfileData();
  pageIsLoading.value = false;
});
</script>
<template>
  <div class="container-fluid">
    <div class="page-header min-height-200" />
    <div class="row">
      <div class="col-md-2"></div>
      <div v-if="pageIsLoading" class="col-md-8">
        <loader />
      </div>
      <div v-else class="col-md-8">
        <div class="card shadow-lg mt-n6">
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto">
                <div class="avatar avatar-xl position-relative overflow-hidden">
                  <img :src="userInfo.imageUrl !== null ? userInfo.imageUrl : sisconImg" alt="profile_image"
                    class="shadow-sm height-40 w-100 border-radius-lg" />
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1">{{ userInfo.fname + " " + userInfo.lname }}</h5>
                  <p class="mb-0 font-weight-bold text-sm">{{ roleText }}</p>
                </div>
              </div>
              <div class="mx-auto mt-3 col-lg-7 col-md-8 my-sm-auto ms-sm-auto me-sm-0">
                <div class="nav-wrapper d-flex flex-row align-items-center justify-content-end gap-3">
                  <argon-button id="showInfo" color="dark" size="md" variant="outline" :active="true" :activating="true"
                    class="d-flex flex-row gap-3 align-items-center">
                    <i class="fa-solid fa-database"></i>
                    Información
                  </argon-button>
                  <argon-button id="showConfig" color="dark" size="md" variant="outline" :activating="true"
                    class="d-flex flex-row gap-3 align-items-center">
                    <i class="fa-solid fa-gears"></i>
                    Configuraciones
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4 container-fluid">
          <div class="row">
            <div class="card">
              <div class="card-header pb-0">
                <div class="text-center">
                  <p class="mb-0">Ver o editar MI PERFIL</p>
                </div>
              </div>
              <div class="card-body">
                <p class="text-uppercase text-sm">Información de usuario</p>
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Nombre</label>
                    <argon-input type="text" v-model="userInfo.fname" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Apellido</label>
                    <argon-input type="text" v-model="userInfo.lname" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Usuario</label>
                    <argon-input type="text" autoComplete="username" v-model="userInfo.username" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Correo electrónico</label>
                    <argon-input type="email" v-model="userInfo.email" />
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4 my-3">
                    <argon-button id="changePassword" color="light" size="sm" variant="outline"
                      :active="!updatePassword" class="d-flex flex-row gap-2 align-items-center"
                      @click="handleUpdatePassword">
                      <i class="fa-solid fa-key"></i>
                      Actualizar contraseña
                    </argon-button>
                    <!-- <div class="form-check ms-3">
                      <input id="changePassword" class="form-check-input" type="checkbox" value="update"
                        @change="handleUpdatePassword">
                      <label for="changePassword" class="form-check-label">
                        Actualizar contraseña
                      </label>
                    </div> -->
                  </div>
                  <div v-show="updatePassword" class="row">
                    <div class="col-md-6">
                      <label for="example-text-input" class="form-control-label">Contraseña</label>
                      <argon-input id="password" type="password" :success="checkedPasswords" v-model="userInfo.password"
                        :error="!checkPassword" />
                    </div>
                    <div class="col-md-6">
                      <label for="example-text-input" class="form-control-label">Repetir contraseña</label>
                      <argon-input id="repeatPassword" type="password" :success="checkedPasswords"
                        v-model="userInfo.repeatPassword" :error="!checkPassword" @keyup="handleCheckPasswords" />
                    </div>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <p class="text-uppercase text-sm">Información de contacto</p>
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label">Dirección</label>
                    <argon-input type="text" v-model="userInfo.address" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Ciudad</label>
                    <argon-input type="text" v-model="userInfo.city" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Provincia</label>
                    <argon-input type="text" v-model="userInfo.province" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">País</label>
                    <argon-input type="text" v-model="userInfo.country" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Código postal</label>
                    <argon-input type="text" v-model="userInfo.zipCode" />
                  </div>
                  <div class="col-md-4">
                    <label for="example-text-input" class="form-control-label">Teléfono</label>
                    <div class="form-group">
                      <div class="input-group">
                        <span class="input-group-text">
                          <i class="fa-solid fa-phone"></i>
                        </span>
                        <input type="tel" class="form-control" @input="formatPhoneNumber" v-model="userInfo.phone"
                          pattern="\d*" />
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="horizontal dark" />
                <div class="row d-flex justify-content-center mt-4">
                  <div class="w-30">
                    <argon-button id="saveUserData" color="dark" size="md"
                      class="d-flex flex-row gap-3 align-items-center justify-content-center w-100" @click="preSendInfo"
                      :disabled="showLoadingButton">
                      <l-bouncy v-if="showLoadingButton" size="30" speed="1" color="white" stroke-length=".15" />
                      <div v-else>
                        <i class="fa-regular text-lg fa-floppy-disk me-2"></i>
                        Guardar
                      </div>
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
