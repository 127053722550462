<script setup>
/* eslint-disable */
import backgroundBusiness from '@assets/img/back-business.jpg';
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { makePostRequest } from "@/utils/querysAPI.js";
import { showToast } from "@/utils/modalShow.js";
import { loginUser } from '@/utils/manageSessions.js';
import iconImg from '@/assets/img/icon-rounded.png';
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { bouncy } from 'ldrs';

bouncy.register();

const body = document.getElementsByTagName("body")[0];
const store = useStore();
const router = useRouter();
const emailUser = ref('');
const password = ref('');
const rememberme = ref(false);
const showLoading = ref(false);

const urlLogin = '/users/login';

const sendInfoLogin = async () => {
  showLoading.value = true;
  // rememberme.value = document.getElementById('rememberMe').checked;

  try {
    const body = {
      userEmail: emailUser.value,
      password: password.value,
      rememberme: rememberme.value,
    };
    const successLogin = await makePostRequest(urlLogin, body);

    if (!successLogin.success) {
      showLoading.value = false;
      showToast.fire({
        icon: "error",
        title: "Usuario y/o contraseña incorrectos."
      });
    } else {
      loginUser(successLogin);
      router.push('/dashboard');
    }

  } catch (error) {
    console.log(error);
    showLoading.value = false;
    if (error.response?.status === 429) {
      showToast.fire({
        icon: "error",
        title: "Demasiados intentos de inicio de sesión detectado. Por favor, inténtalo de nuevo más tarde."
      });
    } else if (error.response?.status === 400) {
      showToast.fire({
        icon: "error",
        title: error.response.data.msg,
      });
    } else {
      showToast.fire({
        icon: "error",
        title: "Ha surgido un error al intentar iniciar la sesión."
      });
    }
  }
};

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  body.classList.add("bg-gray-100");
});
</script>
<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <!-- <div class="col-md-12">
                  <div class="image-container mx-auto">
                    <img class="w-100 h-100 error-icon" :src="iconImg" alt="Siscon">
                  </div>
                </div> -->
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Inicia sesión</h4>
                  <p class="mb-0">Ingresa tu email o usuario y password</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="sendInfoLogin">
                    <div class="mb-3">
                      <el-input
                        name="email"
                        placeholder="Email o usuario"
                        v-model.trim="emailUser"
                        type="text"
                        size="large"
                      />
                    </div>
                    <div class="mb-3">
                      <el-input
                        name="password"
                        placeholder="Contraseña"
                        v-model.trim="password"
                        type="password"
                        size="large"
                        show-password
                      />
                    </div>
                    <argon-switch id="rememberMe" name="remember-me"
                      :checked="rememberme">Recuérdame</argon-switch>

                    <div class="text-center">
                      <argon-button class="mt-4" variant="gradient" color="info" fullWidth size="lg"
                        :disabled="showLoading">
                        <l-bouncy v-show="showLoading" size="25" speed="1" color="white" stroke-length=".15" />
                        <span v-show="!showLoading">Inicia sesión</span>
                      </argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    ¿No tienes una cuenta?
                    <router-link to="/signup">
                      <span class="text-success text-gradient font-weight-bold">Registrarse</span>
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="`
                  background-image: url(${backgroundBusiness});
                  background-size: cover;
                `">
                <span class="mask bg-gradient-info opacity-6"></span>
                <div class="image-container-side mx-auto position-relative">
                  <img class="w-100 h-100 error-icon" :src="iconImg" alt="Siscon">
                </div>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">
                  Bienvenido a Siscon
                </h4>
                <p class="text-white position-relative">
                  Gestiona tus ventas, productos y más con Siscon, tu aliado en el punto de venta. ¡Inicia sesión y
                  comienza
                  a crecer!.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<style scoped>
.image-container {
  width: 100px;
  height: 100px;
}

.image-container-side {
  width: 150px;
  height: 150px;
}
</style>
