<script setup>
import { makeGetRequest } from "@/utils/querysAPI.js";
import ArgonBadge from "@components/ArgonBadge.vue";
import { onBeforeMount, ref, computed, onMounted } from "vue";
import { useRouter } from 'vue-router';
import { getUserRoleText } from "@/utils/helper.js";
import Loader from '@/views/components/Loader.vue';
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";
import { showModal } from "@/utils/modalShow.js";
import NoDocument from '@/views/components/NoDocument.vue';

// import Siscon from "@/assets/img/icon-rounded-100.png";

const store = useStore();
const router = useRouter();
const users = ref([]);
/* const users = reactive([
    {
        name: "Imogene Rivers",
        user: "Rivas",
        email: "proin.velit@outlook.couk",
        status: "No",
        role: "4",
        date: "2024-08-15 15:10:53",
        image: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Imogene Rivers",
        user: "Rivas",
        email: "proin.velit@outlook.couk",
        status: "No",
        role: "4",
        date: "2024-08-15 15:10:53",
        image: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Imogene Rivers",
        user: "Rivas",
        email: "proin.velit@outlook.couk",
        status: "No",
        role: "4",
        date: "2024-08-15 15:10:53",
        image: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Kuame Mathews",
        user: "Ratliff",
        email: "dignissim.magna@aol.com",
        status: "No",
        role: "3",
        date: "2023-12-04 18:40:22",
        image: "",
    },
    {
        name: "Echo Wright",
        user: "Scott",
        email: "lacinia.vitae@protonmail.org",
        status: "No",
        role: "4",
        date: "2025-04-20 07:13:26",
        image: "",
    },
    {
        name: "Kyle Riddle",
        user: "Burks",
        email: "nec@icloud.com",
        status: "Yes",
        role: "6",
        date: "2024-10-28 14:56:29",
        image: "https://plus.unsplash.com/premium_photo-1658527049634-15142565537a?q=80&w=1288&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Angela Fuller",
        user: "Terry",
        email: "non.leo.vivamus@icloud.couk",
        status: "Yes",
        role: "6",
        date: "2024-09-16 09:41:45",
        image: "",
    },
    {
        name: "Noelle Leach",
        user: "Sweet",
        email: "dictum.proin@hotmail.couk",
        status: "Yes",
        role: "1",
        date: "2023-06-14 10:30:42",
        image: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Jocelyn Houston",
        user: "Britt",
        email: "mauris.eu.turpis@hotmail.com",
        status: "Yes",
        role: "2",
        date: "2023-08-30 15:44:31",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Camden Stout",
        user: "Pope",
        email: "sed.hendrerit.a@yahoo.couk",
        status: "Yes",
        role: "4",
        date: "2024-01-16 02:19:43",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1361&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Camden Stout",
        user: "Pope",
        email: "sed.hendrerit.a@yahoo.couk",
        status: "Yes",
        role: "4",
        date: "2024-01-16 02:19:43",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1361&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Camden Stout",
        user: "Pope",
        email: "sed.hendrerit.a@yahoo.couk",
        status: "Yes",
        role: "4",
        date: "2024-01-16 02:19:43",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1361&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Camden Stout",
        user: "Pope",
        email: "sed.hendrerit.a@yahoo.couk",
        status: "Yes",
        role: "4",
        date: "2024-01-16 02:19:43",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1361&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Camden Stout",
        user: "Pope",
        email: "sed.hendrerit.a@yahoo.couk",
        status: "Yes",
        role: "4",
        date: "2024-01-16 02:19:43",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1361&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Camden Stout",
        user: "Pope",
        email: "sed.hendrerit.a@yahoo.couk",
        status: "Yes",
        role: "4",
        date: "2024-01-16 02:19:43",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1361&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Camden Stout",
        user: "Pope",
        email: "sed.hendrerit.a@yahoo.couk",
        status: "Yes",
        role: "4",
        date: "2024-01-16 02:19:43",
        image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1361&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Teagan Wyatt",
        user: "Buckley",
        email: "duis@icloud.net",
        status: "No",
        role: "3",
        date: "2024-02-04 10:06:59",
        image: "https://images.unsplash.com/photo-1599566150163-29194dcaad36?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Alika Snyder",
        user: "Mcguire",
        email: "nunc.ullamcorper@hotmail.ca",
        status: "Yes",
        role: "2",
        date: "2024-08-03 09:29:10",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Alika Snyder",
        user: "Mcguire",
        email: "nunc.ullamcorper@hotmail.ca",
        status: "Yes",
        role: "2",
        date: "2024-08-03 09:29:10",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Alika Snyder",
        user: "Mcguire",
        email: "nunc.ullamcorper@hotmail.ca",
        status: "Yes",
        role: "2",
        date: "2024-08-03 09:29:10",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Alika Snyder",
        user: "Mcguire",
        email: "nunc.ullamcorper@hotmail.ca",
        status: "Yes",
        role: "2",
        date: "2024-08-03 09:29:10",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Alika Snyder",
        user: "Mcguire",
        email: "nunc.ullamcorper@hotmail.ca",
        status: "Yes",
        role: "2",
        date: "2024-08-03 09:29:10",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Alika Snyder",
        user: "Mcguire",
        email: "nunc.ullamcorper@hotmail.ca",
        status: "Yes",
        role: "2",
        date: "2024-08-03 09:29:10",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
        name: "Alika Snyder",
        user: "Mcguire",
        email: "nunc.ullamcorper@hotmail.ca",
        status: "Yes",
        role: "2",
        date: "2024-08-03 09:29:10",
        image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
]); */
const loadingContent = ref(false);
const currentPage = ref(1);
const itemsPerPage = computed(() => store.state.itemsPerPage);
const totalPages = computed(() => Math.ceil(users.value.length / itemsPerPage.value));

const paginatedItems = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage.value;
    const endIndex = startIndex + itemsPerPage.value;
    return users.value.slice(startIndex, endIndex);
});

const setPage = (page) => {
    if (currentPage.value !== page) {
        currentPage.value = page;
    }
};

const getSequentialNumber = (index) => {
    return (currentPage.value - 1) * itemsPerPage.value + index + 1;
};
const userHasElements = computed(() => users.value.length > 0 ? true : false);
const noDocClass = computed(() => userHasElements.value ? 'col-lg-10' : 'col-lg-12');

/* const loadUsersList = async () => {
    store.dispatch('loadUsers');
}; */

const loadData = async () => {
    try {
        const request = await makeGetRequest('/users/list');
        if (request.success === true) {
            users.value = request.data;
            loadingContent.value = false;
            return;
        } else {
            loadingContent.value = false;
            throw new Error(request);
        }
    } catch (error) {
        console.log(error);
    }
};

// Modal
const findUserInfo = (id) => {
    return users.value.find(user => user.userId === id);
};
const openBillActions = (id) => {
    const userInfo = findUserInfo(id);
    const userName = userInfo.fname + " " + userInfo.lname;
    showModal({
        icon: "info",
        title: userName,
        text: "Seleccione una acción para el usuario",
        showCancel: true,
        showConfirm: true,
        cancelText: "Cerrar",
        confirmText: "Editar",
        outClick: true,
    }).then(async (result) => {
        if (result.isConfirmed) {
            router.push(`/users/edit/${id}`);
        }
    });
};

const parseUserRole = (role) => getUserRoleText(role);

onBeforeMount(() => {
    store.state.openedMenu = '';
});
onMounted(async () => {
    loadingContent.value = true;
    await loadData();
});
</script>
<template>
    <div v-if="loadingContent">
        <Loader />
    </div>
    <div v-else class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="row mt-4">
                    <div v-if="userHasElements" class="col-md-1" />
                    <div class="mb-lg-0 mb-4" :class="noDocClass">
                        <div class="card">
                            <div v-if="userHasElements"
                                class="p-3 pb-0 card-header d-flex justify-content-between align-items-center">
                                <div class="d-flex justify-content-between">
                                    <h6 class="mb-2">Todos los clientes</h6>
                                </div>
                                <div class="pe-md-3 d-flex col-md-4 align-items-center justify-content-center">
                                    <div class="input-group">
                                        <span class="input-group-text text-body">
                                            <i class="fa-solid fa-bag-shopping" aria-hidden="true"></i>
                                        </span>
                                        <input type="text" class="form-control" placeholder="Buscar usuario..." />
                                    </div>
                                </div>
                            </div>
                            <div v-else class="w-100">
                                <no-document iconSVG="NoDocumentSVG" text="No se encontraron registros"
                                    redirectTo="/users/new" redirectText="Ingresar un nuevo usuario" />
                            </div>
                            <div v-if="userHasElements" class="table-responsive position-relative px-4 py-2">
                                <table class="table table-hover align-items-center">
                                    <thead>
                                        <tr>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                #
                                            </th>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                Nombre
                                            </th>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                                                Usuario
                                            </th>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                Rol
                                            </th>
                                            <th
                                                class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2">
                                                Estado
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(user, index) in paginatedItems" :key="index">
                                            <td>
                                                <div>
                                                    <h6
                                                        class="mb-0 font-weight-bolder text-sm text-center image-rounded text-info">
                                                        {{ getSequentialNumber(index) }}</h6>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="px-2 py-1 d-flex align-items-center">
                                                    <!-- <div class="image-container">
                                                        <img :src="user.imageUrl !== null ? user.imageUrl : Siscon"
                                                            alt="User image" />
                                                    </div> -->
                                                    <div class="ms-4">
                                                        <h6 class="mb-0 text-sm">{{ user.fname + " " + user.lname }}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <h6 class="mb-0 text-sm">{{ user.username }}</h6>
                                            </td>
                                            <td>
                                                <div>
                                                    <h6 class="mb-0 text-sm">{{ parseUserRole(user.users_business.role) }}</h6>
                                                </div>
                                            </td>
                                            <td class="text-sm align-middle">
                                                <div class="text-center col">
                                                    <argon-badge v-if="user.status" variant="gradient" color="success">
                                                        Activo
                                                    </argon-badge>
                                                    <argon-badge v-else variant="gradient" color="danger">
                                                        Inactivo
                                                    </argon-badge>
                                                </div>
                                            </td>
                                            <td class="align-middle">
                                                <el-tooltip class="box-item" effect="dark"
                                                    content="Opciones de usuario" placement="top">
                                                    <button class="btn btn-link text-dark mb-0"
                                                        @click="openBillActions(user.userId)">
                                                        <i class="fa-solid fa-bars text-lg" aria-hidden="true"></i>
                                                    </button>
                                                </el-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div v-if="users.length > itemsPerPage"
                                        class="col-md-4 d-flex justify-content-center">
                                        <paginate :page-count="totalPages" :click-handler="setPage" :page-range="2"
                                            :prev-text="'<'" :next-text="'>'"
                                            :container-class="'pagination pagination-info pagination-lg cursor-pointer'" />
                                    </div>
                                    <div v-else class="col-md-4" />
                                    <div
                                        class="text-center d-flex flex-column align-items-center justify-content-center col-md-4">
                                        <p class="text-secondary text-xs opacity-9">
                                            Se encontraron <strong>{{ users.length }}</strong> clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.image-container {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
